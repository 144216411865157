<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


export default {
  data() {
    return {
      faceMeshInstance: null, // 用于存储 FaceMesh 实例
    };
  },
  mounted() {
    this.initFaceMesh();
  },
  methods: {
    initFaceMesh() {
      this.faceMeshInstance = new FaceMesh({
        locateFile: (file) => {
          return `/resource/${file}`;
        },
      });

      this.faceMeshInstance.setOptions({
        maxNumFaces: 1,
        refineLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
        useCpuInference: true,
      });

      // FaceMesh 的其他设置或全局回调
      this.faceMeshInstance.onResults(this.faceMeshResults);

      // 将 faceMesh 实例绑定到根实例
      this.$root.faceMesh = this.faceMeshInstance;
    },
    faceMeshResults(results) {
      // 处理 FaceMesh 结果的全局回调函数
      console.log(results);
    },
  },
};
</script>


<style lang="scss">
*{
  list-style: none;
  margin: 0;
  padding: 0;
}
#app {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  touch-action: manipulation;
}

</style>
