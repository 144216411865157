import Vue from 'vue'
import VueRouter from 'vue-router'
import { i18n } from '@/main'; 

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/face',
    name: 'face',
    component: () => import('../views/Face/Face.vue')
  },

  {
    path: '/faceresult',
    name: 'faceresult',
    component: () => import('../views/FaceResult/FaceResult.vue'),
  }, {
    path: '/faceFailed',
    name: 'faceFailed',
    component: () => import('../views/FaceFailed/index.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
  // // 如果当前路由是/faceresult 且目标路由是/faceFailed，则取消导航
  // if (from.path === '/faceresult' && to.path === '/faceFailed') {
  //   return;
  // }
  // next();
  router.beforeEach((to, from, next) => {
    const token = "2u1892u8192";
    const verify_id = "2";
    const callback = "ccc";
    const defaultLanguage = 'en';
  
    // 检查 URL 是否已包含所需的查询参数
    if (to.query.token === token && to.query.verify_id === verify_id && to.query.callback === callback) {
      // 设置语言，如果 `language` 查询参数存在并且是支持的语言
      const language = to.query.language || defaultLanguage;
      const supportedLanguages = ['en', 'id', 'es', 'pt', 'zh'];
  
      if (supportedLanguages.includes(language) && i18n.locale !== language) {
        i18n.locale = language;
      }
      
      return next(); // 继续导航
    }
  
    // 否则，添加这些参数并导航
    next({
      path: to.path,
      query: {
        ...to.query,
        token,
        verify_id,
        callback,
        language: defaultLanguage, // 默认添加的语言参数
      }
    });
  });
export default router